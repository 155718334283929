
// Converted Variables


// Custom Media Query Variables


/*

   FONT FAMILY GROUPS
   Docs: http://tachyons.io/docs/typography/font-family/

*/


.sans-serif {
  font-family: $sans-serif;
}

.serif {
  font-family: $serif;
}

.system-sans-serif {
  font-family: sans-serif;
}

.system-serif {
  font-family: serif;
}


/* Monospaced Typefaces (for code) */

/* From http://cssfontstack.com */
code, .code {
  font-family: Consolas,
               monaco,
               monospace;
}

.courier {
  font-family: 'Courier Next',
               courier,
               monospace;
}


/* Sans-Serif Typefaces */

.helvetica {
  font-family: 'helvetica neue', helvetica,
               sans-serif;
}

.avenir {
  font-family: 'avenir next', avenir,
               sans-serif;
}


/* Serif Typefaces */

.athelas {
  font-family: athelas,
               georgia,
               serif;
}

.georgia {
  font-family: georgia,
               serif;
}

.times {
  font-family: times,
               serif;
}

.bodoni {
  font-family: "Bodoni MT",
                serif;
}

.calisto {
  font-family: "Calisto MT",
                serif;
}

.garamond {
  font-family: garamond,
               serif;
}

.baskerville {
  font-family: baskerville,
               serif;
}

