.footer {
  background-color:$branding_blue;	
  color:#fff;
  font-weight: 700;
  font-size: .9em;
  a {
    color:#fff;
    text-decoration: none;
    @extend .dim;
    position:relative;
    &::before {
      content:"";
      display: block;
      position:absolute;
      bottom:-3px;
      left:0;
      width:100%;
      border-bottom:1px dotted #FFF;
      opacity: .3;

    }
  }
  .footer__container {
    p {
      margin:0;
      padding:0;
      text-align: center;
      line-height:1.8em;
    }
    padding:40px 30px;
  }
}