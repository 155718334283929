.c-contact {
  @include reset_list;
  display:flex;
  flex-wrap: wrap;
  .c-contact__col {
  }
  img {
    display:block;
  }
  iframe {
    display:block;
    width:100%;

    @include mobile {
      height:450px;
    }
    @include mobile-xs {
      height:400px;
    }
  }
}