.c-address {
  @include reset_list;
  background-color:$branding_gray--light;
  overflow:hidden;
  .c-address__container {
    @extend .container;
    padding-top:100px;
    padding-bottom:100px;
    @include mobile-xs {
      padding-top:60px;
      padding-bottom:60px;
    }
  }
  .c-address__grid {
    display:grid;
    grid-template-columns: repeat( auto-fit ,minmax(300px, 1fr));
    grid-gap:60px;
    @include mobile {
      grid-template-columns: repeat(2, 1fr);
    }
    @include mobile-xs {
      grid-row-gap:40px;
      grid-column-gap:30px;

    }
  }
  address {
    @extend .fs-normal;
    font-size: 1.2em;
    color:#706F6F;
    max-width: 350px;
    @include tablet {
      font-size:1em;
    }
    ul {
      font-size: 1em;
      li {
        margin-bottom: .3em;
      }
    }
    h5 {
      font-size: 1.2em;
      font-weight:600;
      line-height: 1.5em;
      @include tablet{
        font-size: 1.1em;
        line-height:1.3em;
      }

    }
  }
}