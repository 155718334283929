// e-color
.e-color-branding-txt {
  color:$branding_orange !important;
}
.e-color-branding-txt--oscure {
  color:$branding_orange_2 !important; 
}
.e-color-branding-txt--white {
  color:$branding_white !important;
}
.e-color-branding-txt--black {
  color:$branding_black !important;
}
.e-color-green {
  color:$branding_green !important;
}
.e-color-blue {
  color:$branding_blue !important;
}
.e-color-blue2 {
  color:$branding_blue2 !important;
}



