// VENDOR >> modified
.material-scrolltop {
	cursor: pointer;
	background-color: rgba($branding_white,.8);
	bottom:12px;
  border-radius:0;
  right:12px;
  &, 
  &::before {
    background-image: url(#{$path}assets/svg/arrow_top.svg);
    background-position: center 50%;
    background-repeat: no-repeat;
    background-size:50% 50%;
  }
  &:hover {
    background-color:$branding_blue;
    background-image: url(#{$path}assets/svg/arrow_top--white.svg);
  }
  &.reveal {
    width: 40px;
    height: 40px;
    z-index: 999 !important;
  }
  @include mobile {
    // display:none !important;
  }
  &::before {
    background: $branding_green; 
  }
}
