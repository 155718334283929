// IMAGE
.e-image-center {
  display: block;
  margin:0 auto;
  &--mobile {
    @include mobile {
      display: block !important;
      margin:0 auto !important;
    }
  }
  &--desktop {
    @include desktop {
      display: block !important;
      margin:0 auto !important;
    }
  }
}
.e-image-float-left {
  float:left;
  padding-right:20px;
}
.e-image-float-right {
  float:right;
  padding-left:20px;
}
.e-image-cover 
{
  object-fit: cover;
}



// IMAGE RADIUS
.e-image-radius-50 {
  border-radius:50%;
}
