// GENERAL THEME EXTRAS
.e-flex-wrap--mobile-xs {
  @include mobile-xs {
    flex-wrap:wrap;
  }
}
.e-center--mobile-xs {
  @include mobile-xs {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}




