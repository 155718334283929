// e-only
.e-only-900px {
  display:none;
  @media screen and (max-width: 900px) {
    display:block;
  }
}

.e-only-desktop {
  display: none !important;
  @include desktop {
    display: block !important;
  }
}
.e-only-tablet {
  display: none;
  @include tablet {
    display: block !important;
  }
}
.e-only-mobile {
  display: none;
  @include mobile {
    display: block !important;
  }
}
