.c-icons-services {
  --c-icons-services-item-width : 150px;
  background-color:$branding_gray--light;
  @include desktop {
    --c-icons-services-item-width : 120px;
  }
  .c-icons-services__grid {
    display:flex;
    flex-wrap:wrap;
    justify-content: center;
    @include tablet {
      padding-top:3em;
    }
    .item {
      margin:4.5em auto;
      text-align: center;
      width:25%;
      @include tablet {
        margin:0em auto 3em;
        width:50%;
      }
      figure {
        display:inline-block;
        width:var(--c-icons-services-item-width);
        .item-image {
          background-color:#FFF;
          border-radius:8px;
          box-shadow: 10px 10px 15px rgba($branding_blue2,.3);
        }
        img {
          width:var(--c-icons-services-item-width);
          height:var(--c-icons-services-item-width);
          padding:30px;
          display:block;
          margin:0 auto;
        }
        figcaption {
          margin-top:30px;
          font-size: 1.4em;
          line-height: 1.1em;
          text-align: left;
          @include desktop {
            font-size: 1.2em;
          }
        }
      }
    }
  }
}