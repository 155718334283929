.c-menu {
  @include reset_list;
  margin:0 20px;
  a {
    @extend .link , .dim;
    color:$branding_blue;
  }
  ul {
    display:flex;
    flex-wrap:wrap;
    @include desktop {
      display:block;
    }
    li {
      a {
        display:block;
        padding:10px 15px;
      }
    }
  }

  #menu-top {

    &.item-0  .c-menu__item--0 a, 
    &.item-1  .c-menu__item--1 a, 
    &.item-2  .c-menu__item--2 a, 
    &.item-3  .c-menu__item--3 a, 
    &.item-4  .c-menu__item--4 a, 
    &.item-5  .c-menu__item--5 a 
    {
      background-color:rgba($branding_blue,.9);
      color:#FFF;
      border-radius: 4px;
    }

  }

.c-menu__btn-mobile {
  display:none;
}
@include desktop {
  width:100%;
  text-align: center;
  .c-menu__btn-mobile {
      display:block;
      text-decoration:none;
      position:absolute;
      right:0px;
      top:15px;
      a {
        padding-top:8px;
        display:block;
        cursor: pointer;
        transform: translateY(-4px);
      }
      .item {
        height:2px;
        display:block;
        width:40px;
        background-color:$branding_black;
        transition: .3s transform;
        &--1 {
        }
        &--2 {
          margin-top:8px;
        }
        &--3 {
          margin-top:8px;
        }
      }
      & {
        a.is-active {
          .item {
            &--1 {
              transform: translateY(10px) rotate(-45deg);
            }
            &--2 {
              visibility: hidden;
            }
            &--3 {
              transform: translateY(-10px) rotate(45deg);
            }
          }
        }
      }
    }
    #menu-top {
      .c-menu__ul {
        display:none;
        #body.is-active-menu-mobile & {
          display:block;
        }
      }
    }
}


}