.c-treatment {
  .c-treatment__col {
    background-color:$branding_gray--light;
    color:#706F6F;
    font-size: 1.5em;
    font-weight:300;
    @include tablet {
      font-size: 1.1em;
    }
    &--text {
      padding:50px;
      display:flex;
      justify-content: center;
      flex-direction: column;
      @include tablet {
        padding:50px 20px;
      }
    }
  }
  .c-treatment__list {
    @include reset_list;
    overflow: hidden;
    padding:100px 0;
    display:block;
    background:url($path + 'assets/img/bg-blue-tractament.png') no-repeat;
    background-size:cover;
    height:100%;
    width:100%;
     @include tablet {
      padding:60px 0;
    }
    ol , ul {
      color:#FFF;
      @extend .e-style-number-theme;
      h3 , h4 {
        margin-bottom: 0em;
        font-weight:bold;
      }
      h3 {
        font-size: 2.2em;
         @include tablet {
          font-size: 1.6em;
        }
      }
      h4 {
        font-size: 2em;
         @include tablet {
          font-size: 1.4em;
        }
      }
      p {
        font-size: 1.2rem;
         @include mobile-xs {
          font-size: 1.1rem;
        }
      }
      li {
        border-bottom:1px solid;
        &::before {
          font-size: 1em;
        }
      }
    }
  }
}