
// Converted Variables


// Custom Media Query Variables


/*

   TYPE SCALE
   Docs: http://tachyons.io/docs/typography/scale/

   Base:
    f = font-size

   Modifiers
     1 = 1st step in size scale
     2 = 2nd step in size scale
     3 = 3rd step in size scale
     4 = 4th step in size scale
     5 = 5th step in size scale
     6 = 6th step in size scale

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large
*/

/*
 * For Hero/Marketing Titles
 *
 * These generally are too large for mobile
 * so be careful using them on smaller screens.
 * */

.f-6,
.f-headline {
  font-size: $font-size-headline;
}
.f-5,
.f-subheadline {
  font-size: $font-size-subheadline;
}


/* Type Scale */


.f1 { font-size: $font-size-1; }
.f2 { font-size: $font-size-2; }
.f3 { font-size: $font-size-3; }
.f4 { font-size: $font-size-4; }
.f5 { font-size: $font-size-5; }
.f6 { font-size: $font-size-6; }
.f7 { font-size: $font-size-7; }

@media #{$breakpoint-not-small}{
  .f-6-ns,
  .f-headline-ns { font-size: $font-size-headline; }
  .f-5-ns,
  .f-subheadline-ns { font-size: $font-size-subheadline; }
  .f1-ns { font-size: $font-size-1; }
  .f2-ns { font-size: $font-size-2; }
  .f3-ns { font-size: $font-size-3; }
  .f4-ns { font-size: $font-size-4; }
  .f5-ns { font-size: $font-size-5; }
  .f6-ns { font-size: $font-size-6; }
  .f7-ns { font-size: $font-size-7; }
}

@media #{$breakpoint-medium} {
  .f-6-m,
  .f-headline-m { font-size: $font-size-headline; }
  .f-5-m,
  .f-subheadline-m { font-size: $font-size-subheadline; }
  .f1-m { font-size: $font-size-1; }
  .f2-m { font-size: $font-size-2; }
  .f3-m { font-size: $font-size-3; }
  .f4-m { font-size: $font-size-4; }
  .f5-m { font-size: $font-size-5; }
  .f6-m { font-size: $font-size-6; }
  .f7-m { font-size: $font-size-7; }
}

@media #{$breakpoint-large} {
  .f-6-l,
  .f-headline-l {
    font-size: $font-size-headline;
  }
  .f-5-l,
  .f-subheadline-l {
    font-size: $font-size-subheadline;
  }
  .f1-l { font-size: $font-size-1; }
  .f2-l { font-size: $font-size-2; }
  .f3-l { font-size: $font-size-3; }
  .f4-l { font-size: $font-size-4; }
  .f5-l { font-size: $font-size-5; }
  .f6-l { font-size: $font-size-6; }
  .f7-l { font-size: $font-size-7; }
}
