/*  Globals / Abstracts */
@import 
"global/vars",
"global/mixins"
;

/* Vendors */
@import "vendor/tachyons-sass/tachyons"; //https://tachyons.io/

@import "../../node_modules/flexboxgrid/css/flexboxgrid"; //http://flexboxgrid.com/
//@import "../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css"; //http://fancyapps.com/fancybox/3/
// @import "../../node_modules/material-scrolltop/dist/material-scrolltop"; //https://github.com/bartholomej/material-scrolltop

// Vendor (Modified)
@import 
"vendor/v-flexboxgrid",
"vendor/v-thachyons",
"vendor/v-material-scrolltop"
// @import "vendor/v-fancybox";
;

/* Base */
@import 
"base/fonts",
"base/theme"
// @import "base/wp";
;

// u- Utils 
@import 
"utils/u-ratio",
"utils/--dev"
;

// Partials
@import 
"partials/body",
"partials/header",
"partials/main",
"partials/footer"
;

// State / State JS
@import 
"state/s-general"
;

// Elements 
@import 
"elements/e-elements"
;



// Components
@import
"components/c-icons-services",
"components/c-the-lice",
"components/c-address",
"components/c-products",
"components/c-treatment",
"components/c-slider-home",
"components/c-menu",
"components/c-contact",
"components/c-social"
;



















