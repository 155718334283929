.header {
  background-color:#FFF;
  @include reset_list;
  a {
    @extend .link , .dim;
  }
  padding:30px 0 0 0;
  .header__gradient {
    background : -moz-linear-gradient(0% 50% 0deg,rgba(70, 104, 175, 1) 5.16%,rgba(73, 130, 184, 1) 12.1%,rgba(76, 152, 192, 1) 20.08%,rgba(78, 169, 199, 1) 29.46%,rgba(79, 181, 203, 1) 41.01%,rgba(80, 188, 205, 1) 57.05%,rgba(80, 190, 206, 1) 100%);
    background : -webkit-linear-gradient(0deg, rgba(70, 104, 175, 1) 5.16%, rgba(73, 130, 184, 1) 12.1%, rgba(76, 152, 192, 1) 20.08%, rgba(78, 169, 199, 1) 29.46%, rgba(79, 181, 203, 1) 41.01%, rgba(80, 188, 205, 1) 57.05%, rgba(80, 190, 206, 1) 100%);
    background : -webkit-gradient(linear,0% 50% ,100% 50% ,color-stop(0.0516,rgba(70, 104, 175, 1) ),color-stop(0.121,rgba(73, 130, 184, 1) ),color-stop(0.2008,rgba(76, 152, 192, 1) ),color-stop(0.2946,rgba(78, 169, 199, 1) ),color-stop(0.4101,rgba(79, 181, 203, 1) ),color-stop(0.5705,rgba(80, 188, 205, 1) ),color-stop(1,rgba(80, 190, 206, 1) ));
    background : -o-linear-gradient(0deg, rgba(70, 104, 175, 1) 5.16%, rgba(73, 130, 184, 1) 12.1%, rgba(76, 152, 192, 1) 20.08%, rgba(78, 169, 199, 1) 29.46%, rgba(79, 181, 203, 1) 41.01%, rgba(80, 188, 205, 1) 57.05%, rgba(80, 190, 206, 1) 100%);
    background : -ms-linear-gradient(0deg, rgba(70, 104, 175, 1) 5.16%, rgba(73, 130, 184, 1) 12.1%, rgba(76, 152, 192, 1) 20.08%, rgba(78, 169, 199, 1) 29.46%, rgba(79, 181, 203, 1) 41.01%, rgba(80, 188, 205, 1) 57.05%, rgba(80, 190, 206, 1) 100%);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#4668AF', endColorstr='#50BECE' ,GradientType=0)";
    background : linear-gradient(90deg, rgba(70, 104, 175, 1) 5.16%, rgba(73, 130, 184, 1) 12.1%, rgba(76, 152, 192, 1) 20.08%, rgba(78, 169, 199, 1) 29.46%, rgba(79, 181, 203, 1) 41.01%, rgba(80, 188, 205, 1) 57.05%, rgba(80, 190, 206, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4668AF',endColorstr='#50BECE' , GradientType=1);
    margin-top:30px;
    width:100%;
    display:block;
    height:16px;
  }
  .header__logo {
    margin-top:30px;
  }
  .header__langs-item {
      a {
        font-weight: 400;
        color:$branding_gray;
        &:hover {
          color:$branding_black;
        }
      }
      #body.body--lang-cat &--cat a ,
      #body.body--lang-esp &--esp a 
      {
        color:#000;
        font-weight: 500;
      }
    a {
    }
  }
  .header__langs {
    nav {
      margin-bottom: 1.5em;
      width:100%;
      text-align:center;
      ul {
        display:flex;
        flex-wrap:wrap;
        justify-content: flex-end;
        @include desktop {
          margin-top: 2em;
          justify-content:center;
        } 
      li {
        position:relative;
        a {
          padding-right:10px;
          padding-left:10px;
          &::before {
            content:"";
            position:absolute;
            height:70%;
            width:2px;
            top:50%;
            transform:translateY(-50%);
            background-color:$branding_black;
            right:0;
          }
        }
        &:last-child {
          a {
            &::before {
              display:none;
            }
          }
        }
      }
    }
  }
  @include desktop {
    display:none;
  }
}
.header__grid {
  display:flex;
  align-items: center;
  justify-content: space-between;
  @include desktop {
    display:block;
    align-items: inherit;
    justify-content: inherit;
  }
}
.header__col {
  &--left {
  }
  &--right {
  }
}
&.is-active {
  position:sticky;
  top:0;
  z-index: 2;
  right:0;
  left:0;
  background-color:#FFF;
  // padding:0;
  animation: e-fade-in .8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  .header__langs {
    nav {
      margin-bottom: .5em;
    }
  }
  .header__gradient {
    margin-top:4px;
    height:6px;
  }
  .header__logo {
    margin-top:0px;
    img {
      width:200px;
    }
  }
}
@include desktop {
  position:sticky;
  top:0;
  z-index: 2;
  right:0;
  left:0;
  background-color:#FFF;
  padding:10px 0 0 0;
  animation: e-fade-in .8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  .header__langs {
    nav {
      margin-bottom: .5em;
    }
  }
  .header__gradient {
    margin-top:4px;
    height:6px;
  }
  .header__logo {
    margin-top:0px;
    img {
      width:200px;
    }
  }


}
}