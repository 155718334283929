.c-the-lice {
  .c-the-lice__figure {
    img {
      display:block;
      width:100%;
    }
  }
  .c-the-lice__content {
    overflow:hidden;
    background-color:$branding_blue2;
    color:#FFF;
    font-size: 1.5em;
    font-weight:300;
    padding:40px 0;
    @include tablet {
      font-size: 1.1em;
    }
    .item {
      h2 {
        @extend .fw3, .f2-m , .mb4, .mt5;
        &:first-child {
          // margin-top:0;
        }
      }
    }
  }
}