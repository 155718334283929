
// Converted Variables


// Custom Media Query Variables


/*

   TYPOGRAPHY
   http://tachyons.io/docs/typography/measure/

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/



/* Measure is limited to ~66 characters */
.measure {
  max-width: $measure;
}

/* Measure is limited to ~80 characters */
.measure-wide {
  max-width: $measure-wide;
}

/* Measure is limited to ~45 characters */
.measure-narrow {
  max-width: $measure-narrow;
}

/* Book paragraph style - paragraphs are indented with no vertical spacing. */
.indent {
  text-indent: 1em;
  margin-top: 0;
  margin-bottom: 0;
}

.small-caps {
  font-variant: small-caps;
}

/* Combine this class with a width to truncate text (or just leave as is to truncate at width of containing element. */

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media #{$breakpoint-not-small} {
  .measure-ns  {
    max-width: $measure;
  }
  .measure-wide-ns {
    max-width: $measure-wide;
  }
  .measure-narrow-ns {
    max-width: $measure-narrow;
  }
  .indent-ns {
    text-indent: 1em;
    margin-top: 0;
    margin-bottom: 0;
  }
  .small-caps-ns {
    font-variant: small-caps;
  }
  .truncate-ns {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media #{$breakpoint-medium} {
  .measure-m {
    max-width: $measure;
  }
  .measure-wide-m {
    max-width: $measure-wide;
  }
  .measure-narrow-m {
    max-width: $measure-narrow;
  }
  .indent-m {
    text-indent: 1em;
    margin-top: 0;
    margin-bottom: 0;
  }
  .small-caps-m {
    font-variant: small-caps;
  }
  .truncate-m {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media #{$breakpoint-large} {
  .measure-l {
    max-width: $measure;
  }
  .measure-wide-l {
    max-width: $measure-wide;
  }
  .measure-narrow-l {
    max-width: $measure-narrow;
  }
  .indent-l {
    text-indent: 1em;
    margin-top: 0;
    margin-bottom: 0;
  }
  .small-caps-l {
    font-variant: small-caps;
  }
  .truncate-l {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
