.c-slider-home {
  .item {
    position:relative;
    figure {
      img {
        display:block;
      }
      figcaption {
        @extend .container;
        right:0;
        left:0;
        position:absolute;
        top:50%;
        transform:translateY(-50%);
        .item-mark {
          overflow: hidden;
          background-color:$branding_blue2;
          color:#FFF;
          padding:40px 70px;
          display:inline-block;
          max-width: auto;
          text-align: center;
          letter-spacing: 1px;
          font-size:2.3rem;
          line-height: 1.3em;
          font-weight: 300;
        }
      }
    }
  }
  @include desktop {
    .item {
      figure {
        figcaption {
          padding:0;
          position:initial;
          transform:initial;
          top:initial;
          .item-mark {
            width:100%;
            padding:30px 50px;
            font-size:1.8rem;
          }
        }
      }
    }
  }
}