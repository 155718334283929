// e-btn
.e-btn {
  background-color:$branding_white;
  text-decoration:none;
  padding:14px 30px;
  border-radius:0px;
  position:relative;
  border:0;
  cursor:pointer;
  box-sizing:border-box;
  color:#000;
  transition:.2s;
  display:inline-block;
  border-radius: 60px;
  &::after {
    // content:(">");
    // margin-left:.5em;
    // transition:.2s;
  }
  &:hover {
    background-color:$branding_black;
    color:$branding_white;
    box-shadow:0px 0px 5px #666;
    &::after {
      margin-left:1em;
    }
  }
  &--no-arrow {
    &::after ,
    &::after 
    {
      display:none;
    }
  }
  &--transparent {
    background-color:transparent;
    color:$branding_blue2;
    font-weight: 500;
    border:2px solid $branding_blue2;
    box-sizing:border-box;
    display:inline-block;

  }
  &--brown {
    background-color:$branding_brown;
    color:#FFF;
    font-weight: 500;
    border:0;
    box-sizing:border-box;
    display:inline-block;
  }
  &--big-padding {
    padding-right:40px;
    padding-left:40px;
  }
}