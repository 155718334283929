.c-products {
  .c-products__image {
    background-color:$branding_gray;
    width:100%;
    img {
      @extend .container;
      display:block;
      margin:0 auto;
      object-fit: contain;
    }
  }
  .c-products__content {
    @extend .container;

    &-text {

      color:#706F6F;
      font-size: 1.5em;
      font-weight:300;
      margin:40px 0;
      @include tablet {
        margin:40px 10px;
        font-size: 1.1em;

      }

    }

  }
}